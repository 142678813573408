<template>
  <div>
    <!-- Page Heading -->
      <v-toolbar flat>
        <pageHeading pageTitle="Transactions Report"/>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on }">
              <v-btn 
                v-show="canDo && canDo.can.includes('manual_driver_topup')"
                class="mx-2" depressed dark color="primary" v-on="on">
                <v-icon left dark>mdi-wallet-plus</v-icon> Manual Topup
              </v-btn>
          </template>
          <!-- Modal Content -->
          <v-card>
            <v-card-title>
              <span class="headline">Manual Topup</span>
            </v-card-title>

            <v-card-text>
              <v-form v-model="form_valid" ref="form">
                <v-container>
                  <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field v-model="editedItem.employee_no" :rules="[validate_rules.required]" label="Driver No"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <v-text-field  v-model="editedItem.amount" :rules="[validate_rules.required]" type="number" label="Amount"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-select
                                :items="mtTransactionTypes"
                                label="Transaction type"
                                v-model="editedItem.transaction_type"
                                :rules="[validate_rules.required]"
                                no-data-text="Select.."
                                item-text="value"
                                item-value="value"
                                clearable
                                 dense
                            ></v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea label="Note" v-model="editedItem.note" rows="3" no-resize clearable></v-textarea>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <v-textarea label="SMS Message" v-model="editedItem.sms" rows="3" no-resize clearable></v-textarea>
                        </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="preview">Submit</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>

        <!-- Preview Dialog -->
        <v-dialog v-model="preview_dialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Are you sure to manual topup for:</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <strong>Employee No:</strong> {{ editedItem.employee_no }}
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <strong>Amount:</strong> {{ editedItem.amount }} Bht
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <strong>Transaction type:</strong> {{ editedItem.transaction_type}}
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                            <strong>Note:</strong> {{ editedItem.note}}
                        </v-col>
                        <v-col cols="12" sm="12" md="12" v-if="editedItem.sms">
                            <strong>SMS Message:</strong> {{ editedItem.sms}}
                        </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="preview_close">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="save">Confirm</v-btn>
            </v-card-actions>
          </v-card>
          <!-- End Modal Content -->
        </v-dialog>
      </v-toolbar> 
    <v-divider></v-divider>
    <div class="content-wrap">
        <v-toolbar class="search-tools pa-0 mb-10 mt-5" flat>
          <!-- Action Button -->
          <!-- <v-spacer></v-spacer> -->
          <v-row>
            <v-col class="d-flex pl-0" cols="3" sm="3">
                <v-text-field
                label="Transaction ID"
                v-model="options.filter.trans_id"
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
                @keyup.native.enter="getDataFromApi"
                /> 
            </v-col>
            <v-col class="d-flex pl-0" cols="3" sm="3">
                <v-text-field
                label="Order No"
                v-model="options.filter.order_no"
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
                @keyup.native.enter="getDataFromApi"
                /> 
            </v-col>
            <v-col class="d-flex pl-0" cols="3" sm="3">
                <v-text-field
                label="Driver No"
                v-model="options.filter.employee_no"
                hide-details
                prepend-inner-icon="mdi-magnify"
                class="hidden-sm-and-down"
                clearable
                @keyup.native.enter="getDataFromApi"
                /> 
            </v-col>
            <v-col class="d-flex px-0" cols="3" sm="3">
                <v-select
                :items="trans_status_options"
                label="Transaction Status"
                v-model="options.filter.transaction_status"
                no-data-text="Select.."
                item-text="text"
                item-value="value"
                clearable
                hide-details
                ></v-select>
            </v-col>
          <v-col class="d-flex px-0" cols="5" sm="5">
            <v-select
                v-model="options.filter.transaction_type"
                :items="transactionTypes"
                label="Transaction Types"
                multiple
                :single-line="true"
                height="32px"
                >
                <template v-slot:prepend-item>
                    <v-list-item
                    ripple
                    @click="toggle_multi_select"
                    >
                    <v-list-item-action>
                        <v-icon :color="options.filter.transaction_type.length > 0 ? 'indigo darken-4' : ''">{{ selectall_icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                </template>
                </v-select>
          </v-col>
          <v-col class="d-flex" cols="2" sm="2">
            <v-select
                :items="wallet_codes"
                label="Wallet Type"
                v-model="options.filter.wallet_code"
                no-data-text="Select.."
                item-text="text"
                item-value="value"
                clearable
            ></v-select>
          </v-col>
          <v-col class="d-flex" cols="3" sm="3">
            <v-menu
              v-model="filterDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="filter_date_formatted"
                  label="Transaction Date"
                  readonly
                  v-on="on"
                  @click:clear="clearFilterDaterange()"
                  hide-details
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="options.filter.date_range"
                @change="filterDatePicker = false"
                range
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- <v-spacer></v-spacer> -->
          <v-col cols="2" sm="2">
            <v-btn class="mx-2" depressed small color="primary" @click="options.page = 1;getTransactionsReport(options);" :disabled="!allowSearch">
                Search
            </v-btn>
         </v-col>
          </v-row>
        </v-toolbar>
        <v-data-table 
            :headers="headers"
            :items="dataLisings" 
            :options.sync="options"
            :server-items-length="totalResult"
            :loading="loadingDataTable"
            :footer-props="{
              'items-per-page-options': [25, 100, 500]
            }"
            class="text-small"
            no-data-text="There is no data"
            multi-sort
        >
          
          <template #item.amount="{value}">
              {{ value | comma}}
          </template>
          <template #item.transaction_date="{value}">
              <span v-show="value">{{ value | datetime}}</span>
          </template>
          <template #item.ticket="{item}">
              <span v-if="item.ticket">
                  <span v-if="item.ticket.ticket_id">{{ item.ticket.ticket_id}}</span>
                  <a href="" v-if="item.ticket.ticket_id && item.ticket.ticket_link">{{ item.ticket.ticket_id}}</a>
              </span>
          </template>
          <template #item.transaction_status="{item}">
              <span v-if="item.transaction_status || item.transaction_status != 0">
                  <v-chip v-if="item.transaction_status == 1" color="green" dark x-small>Success</v-chip>
                  <v-chip v-else-if="item.transaction_status == -1 && item.verify_rsp_code" color="red darken-4" dark x-small>{{ item.verify_rsp_code }}</v-chip>
                  <v-chip v-else-if="item.transaction_status == -2 && item.notify_rsp_code" color="red darken-4" dark x-small>{{ item.notify_rsp_code }}</v-chip>
              </span>
          </template>
          <template #item.tmw="{item}">
              <span v-if="item.tmw">
                  <v-chip v-if="item.tmw.status == 'Success'" color="green" dark x-small>Success</v-chip>
                  <v-chip v-else color="red darken-4" dark x-small>{{ item.tmw.status }}</v-chip>
              </span>
          </template>
          
          <template #item.action="{item}">
                <!-- <v-btn class="ma-1" outlined color="primary" icon tile small :disabled="['approve_request','approved'].includes(item.status)"> -->
                <v-btn class="ma-1" outlined color="primary" icon tile small v-if="item.transaction_status==-1">
                  <v-icon small @click="retry_tmw(item.id)">mdi-upload</v-icon>
                </v-btn>
          </template>
        </v-data-table>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import pageHeading from '@/components/common/pageHeading.vue';
import _ from 'lodash';

export default {
  name: 'TransactionsReport',
  components: {
      pageHeading
  },
  data: () => ({
    search: '',
    dialog: false,
    preview_dialog: false,
    // datePicker: false,
    filterDatePicker: false,
    // upload_file: null,
    editedItem: {
      employee_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: ''
    },
    defaultItem: {
      employee_no: '',
      amount: 0,
      transaction_type: null,
      note: '',
      sms: ''
    },
    form_valid : false,
    validate_rules : {
        required: v => !!v || 'This field is required',
        select2: v => {
          if(typeof v === 'object'){
            return (v.value !== '') || 'This field is required';
          } else {
            return true
          }
        },
        // select: v => { return (v !== '') || 'This field is required' },
        number: v => /^[0-9]/.test(v) || 'Number only please',
    },
    options: { 
        page: 1,
        itemsPerPage: 25,
        sortBy: ['transaction_date'],
        sortDesc: [true],
        multiSort: true,
        mustSort: true,
        filter: {
            trans_id: '',
            order_no: '',
            employee_no: '',
            transaction_type: [],
            date_range: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            transaction_status: 1,
            wallet_code : ''
        }
    },
    trans_status_options: [
        { value: 1, text: 'Success'},
        { value: -1, text: 'Fail Verify'},
        { value: -2, text: 'Fail Notify'},
    ],
    wallet_codes : [
      {value : 'TPC', text: 'TPC'},
      {value : '1112D', text: '1112D'},
    ]
  }),
  watch: {
    'options.sortBy': function (newVal, oldVal){
         //to work with changes in someOtherProp
        //if(newVal && this.transactions && this.transactions.length > 0){
          this.getTransactionsReport(this.options);
        //}
     },
    dialog (val) {
      val || this.close()
    },
    filterDatePicker (val){
      if(val && this.options.filter.date_range.length == 0){
        this.options.filter.date_range = [ moment().subtract(0, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ];
      }
    }
  },
  methods: {
    ...mapActions(['genCan','getTransactionsReport','getTransactionTypes','sendEmployeeManualTopup']),
    getDataFromApi() {
            this.options.page = 1;
            this.getTransactionsReport(this.options);
    },
    clearFilterDaterange(){
      this.options.filter.date_range = [];
    },
    close () {
      this.dialog = false
    },
    preview () {
      if(this.form_valid){
        this.dialog = false;
        this.preview_dialog = true;
      }
    },
    preview_close (){
        this.preview_dialog = false;
        setTimeout(() => {
            this.editedItem = Object.assign({}, this.defaultItem)
            this.$refs.form.resetValidation() // prevent validate error show again when click add
        }, 300)
    },
    save () {
        this.sendEmployeeManualTopup(this.editedItem);
        this.preview_close()
    },
    toggle_multi_select () {
        this.$nextTick(() => {
          if (this.selectedAll) {
            this.options.filter.transaction_type = []
          } else {
            this.options.filter.transaction_type = this.transactionTypes.slice()
          }
        })
      },
  },
  computed: {
    ...mapGetters(['canDo','loadingDataTable','transactionsReport','transactionTypes','mtTransactionTypes', 'allowSearch']),
    dataLisings() { 
        var res = this.transactionsReport ? this.transactionsReport.data : [];
        return  res;
    },
    totalResult(){
        return (this.transactionsReport ? this.transactionsReport.count : null) || 0;
    },
    headers(){
      return [
        // {text: "ID", value: 'id',width: '90px'},
        {text: "TransactionID", value: 'transaction_id', align: 'left',width: '150px'},
        {text: "Transaction Type", value: 'transaction_type',align: 'center',width: '150px'},
        {text: "Order No", value: 'order_no',align: 'center',width: '150px'},
        {text: "Driver No", value: 'employee_no', align: 'center',width: '150px'},
        {text: "Amount", value: 'amount', align: 'right',width: '100px'},
        {text: "Wallet", value: 'wallet_code', align: 'center',width: '100px'},
        {text: "ZD Ticket", value: 'ticket', align: 'right',width: '100px',sortable:false},
        {text: "Transaction Status", value: 'transaction_status', align: 'right',width: '100px',sortable:false},
        {text: "TMW Status", value: 'tmw', align: 'right',width: '100px'},
        {text: "Transaction Date", value: 'transaction_date', align: 'right',width: '100px'},
        // {text: "Action", value: 'action', sortable: false, align: 'center', width: '110px'}, 
      ]
    },
    // incentive_date_formatted () {
    //     return this.editedItem.incentive_date ? moment(this.editedItem.incentive_date).format('DD-MM-YYYY') : '';
    // },
    filter_date_formatted () {
      if(!this.options.filter.date_range) 
        return '';
        
      var date_format = this.options.filter.date_range.map(x => moment(x).format('DD-MM-YYYY'));
        return typeof date_format == 'object' ? date_format.join(' - ') : '';
    },
    selectedAll () {
        return this.transactionTypes ? (this.options.filter.transaction_type.length === this.transactionTypes.length) : false;
    },
    selectedSome () {
        return this.options.filter.transaction_type.length > 0 && !this.selectedAll;
    },
    selectall_icon () {
        if (this.selectedAll) return 'mdi-close-box'
        if (this.selectedSome) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
      },
  },
  mounted() {
    this.genCan();
    this.getTransactionTypes();
    this.getTransactionsReport(this.options);
  }
};
</script>
<style scoped>
.v-data-table {
  max-width: 100%
}

</style>